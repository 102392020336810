import React from 'react'
import { Navigation, Pagination, A11y, Keyboard } from 'swiper'
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi'

import 'swiper/swiper.min.css'

import {
  SwiperWrap,
  StyledSwiper,
  StyledSwiperSlide,
  NextButton,
  PreviousButton,
  SwiperPagination,
} from './swiper.style'

/*
  When loop is enabled, and breakpoints slidesPerView 
  is bigger than half of the sliders length,
  the loop behaves weirdly, hence forcing values
*/

const formatedBreakpoints = (loop, breakpoints, length) =>
  loop && breakpoints
    ? Object.keys(breakpoints).reduce((attrs, key) => {
        return {
          ...attrs,
          [key]: {
            slidesPerView:
              breakpoints[key].slidesPerView <= length
                ? breakpoints[key].slidesPerView
                : length,
          },
        }
      }, {})
    : breakpoints

const SwiperSlider = ({
  children,
  initialSlide = 0,
  slidesPerView = 4,
  spaceBetween = 30,
  arrows = true,
  usePagination = true,
  loop = true,
  centeredSlides = false,
  freeMode = true,
  delay = 2500,
  speed = 300,
  breakpoints,
  offset,
}) => (
  <SwiperWrap offset={offset}>
    <StyledSwiper
      breakpoints={formatedBreakpoints(
        loop,
        breakpoints,
        parseInt(children.length / 2),
      )}
      centeredSlides={centeredSlides}
      delay={delay}
      freeMode={freeMode}
      initialSlide={initialSlide}
      keyboard={{ enabled: true, onlyInViewport: true }}
      loop={loop}
      modules={[Navigation, Pagination, A11y, Keyboard]}
      navigation={
        arrows && {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        }
      }
      pagination={
        usePagination && {
          el: '.swiper-pagination',
          type: 'bullets',
          clickable: true,
        }
      }
      slidesPerView={slidesPerView}
      spaceBetween={spaceBetween}
      speed={speed}
      wrapperTag="ul"
    >
      {children.map((child) => (
        <StyledSwiperSlide key={child.key} tag="li">
          {child}
        </StyledSwiperSlide>
      ))}
      <SwiperPagination className="swiper-pagination" />
    </StyledSwiper>
    <NextButton className="swiper-button-next">
      <FiChevronRight className="icon" role="img" title="Volgende" />
    </NextButton>
    <PreviousButton className="swiper-button-prev">
      <FiChevronLeft className="icon" role="img" title="Vorige" />
    </PreviousButton>
  </SwiperWrap>
)

export default SwiperSlider
