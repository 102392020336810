import styled, { css } from 'styled-components'
import Anchor from '../../ui/anchor'
import Heading from '../../ui/heading'
import { device } from '../../../theme'

export const FakeButton = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  padding-left: ${(props) => props.pt || '35px'};
  padding-right: ${(props) => props.pt || '35px'};
  font-family: ${(props) => props.theme.fontFamily.heading};
  font-size: 16px;
  transition: ${(props) => props.theme.transition};

  svg {
    display: inline-block;
    position: relative;
    top: 1px;
    margin-left: 4px;
  }

  span {
    display: inline-block;
    color: ${(props) => props.theme.colors.textDark};
    line-height: 1.1em;
  }
`

export const BoxLargeImgInner = styled.div`
  background: #fff;
  overflow: hidden;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  position: relative;
  height: 100%;

  a {
    text-decoration: none;
  }
`

export const BoxLargeImgMedia = styled.div`
  position: relative;

  ${(props) =>
    props.smallIcon &&
    css`
      img {
        height: 40px;
        margin: 30px 35px -15px;
      }
    `}

  &.icon {
    overflow: hidden;
    background-color: #f5f5f5;
    text-align: center;

    img {
      height: 148px;
      max-height: 148px;
      width: auto;
      margin: 0;
    }
  }

  .gatsby-image-wrapper {
    min-height: 200px;
    max-height: 200px;

    img {
      width: 100%;
    }

    @media ${device.mediumOnly} {
      min-height: 148px;
      max-height: 148px;
    }
  }
`

export const ImageWrapper = styled.div`
  position: relative;
  height: 148px;
  overflow: hidden;

  img {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    width: 100%;

    @supports (object-fit: cover) {
      position: static;
      top: auto;
      left: auto;
      transform: none;
      height: 148px;
      object-fit: cover;
    }
  }
`

export const BoxLargeImgContent = styled.div`
  padding-top: ${(props) => props.pt || '32px'};
  padding-left: ${(props) => props.pt || '35px'};
  padding-right: ${(props) => props.pt || '35px'};
  padding-bottom: ${(props) => props.pb || '88px'};
  text-align: ${(props) => props.textalign || 'left'};

  ${(props) =>
    props.color &&
    css`
      h2,
      h3,
      h4,
      h5,
      h6 {
        color: ${props.theme.colors.theme};
      }
    `}
`

export const HeadingWrap = styled(Heading)`
  font-size: 20px;
  font-weight: ${(props) => props.fontweight || 'normal'};
  line-height: ${(props) => props.lineHeight || '1.6rem'};
  margin-bottom: ${(props) => props.mb || '12px'};
  color: ${(props) => props.theme.colors.textDark};
`

export const CategoryWrap = styled.p`
  margin-bottom: 7px;
  font-size: 0.85rem;
  line-height: ${(props) => props.theme.lineHieghts.small};
  font-family: ${(props) => props.theme.fontFamily.heading};
  font-weight: 300;

  @media ${device.small} {
    font-size: ${(props) => props.theme.fontSizesSmall.small};
    line-height: ${(props) => props.theme.lineHieghtsSmall.small};
  }
`

export const TextWrap = styled.p`
  color: ${(props) => props.color || '#696969'};
  margin-bottom: ${(props) => props.mb};
  margin-top: ${(props) => props.mt};
`

export const BoxLargeImgBtnWrap = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  height: 56px;
  line-height: 55px;
  text-align: left;
  font-size: 14px;
  border-top: 1px solid #eee;

  ${(props) =>
    props.color
      ? css`
          color: ${props.color};
        `
      : css`
          color: ${props.theme.colors.theme};
        `}

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transform: translateY(100%);
    transition: ${(props) => props.theme.transition};
    z-index: -1;

    ${(props) =>
      props.color
        ? css`
            background-color: ${props.color};
          `
        : css`
            background-color: ${props.theme.colors.theme};
          `}
  }

  > a,
  > button {
    justify-content: flex-start;
    padding-left: ${(props) => props.pt || '35px'};
    padding-right: ${(props) => props.pt || '35px'};
  }

  .btn-icon {
    top: 2px;
    margin-left: 8px;

    svg {
      font-size: 15px;
    }
  }
`

export const BoxLargeImgLink = styled(Anchor)`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  visibility: hidden;
  opacity: 0;
  text-indent: -99999px;
`

export const BoxLargeImgWrap = styled.div`
  transition: ${(props) => props.theme.transition};
  margin-top: ${(props) => props.mt};
  margin-bottom: ${(props) => props.mb};
  margin-left: ${(props) => props.ml};
  margin-right: ${(props) => props.mr};
  height: 100%;

  &:hover {
    transform: translateY(-5px);

    ${BoxLargeImgLink} {
      visibility: visible;
      opacity: 1;
    }

    ${FakeButton} {
      &,
      span {
        color: ${(props) => props.theme.colors.white};
      }
    }

    ${BoxLargeImgBtnWrap} {
      &::before {
        transform: translateY(0);
      }

      a,
      button {
        color: ${(props) => props.theme.colors.white};
      }
    }
  }

  a {
    transform: none !important;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 5;
    }

    &:hover h4 {
      color: ${(props) => props.theme.colors.theme};
    }

    h4 {
      transition: ${(props) => props.theme.transition};
    }
  }
`
