import React from 'react'
import PropTypes from 'prop-types'
import NavBar, { NavItem, NavLink } from '../../ui/navbar'
import { MainMenuWrap } from './mainmenu.style'

export const MainMenu = ({ headingStyle, menuData, ...props }) => (
  <MainMenuWrap {...props}>
    <NavBar>
      {menuData.map((menu) => (
        <NavItem key={`mainmenu-${menu.id}`}>
          <NavLink path={menu.link} partiallyActive={true}>
            <span>{menu.text}</span>
          </NavLink>
        </NavItem>
      ))}
    </NavBar>
  </MainMenuWrap>
)

MainMenu.propTypes = {
  alignment: PropTypes.string,
  headingStyle: PropTypes.object,
}

MainMenu.defaultProps = {
  alignment: 'center',
  headingStyle: {
    fontSize: '14px',
    mb: '20px',
    texttransform: 'uppercase',
  },
}
