const getSiblings = function (elem) {
  var siblings = []
  var sibling = elem.parentNode.firstChild

  while (sibling) {
    if (sibling.nodeType === 1 && sibling !== elem) {
      siblings.push(sibling)
    }
    sibling = sibling.nextSibling
  }

  return siblings
}

const getClosest = function (elem, selector) {
  if (!Element.prototype.matches) {
    Element.prototype.matches = Element.prototype.msMatchesSelector
  }

  for (; elem && elem !== document; elem = elem.parentNode) {
    if (elem.matches(selector)) return elem
  }

  return null
}

const slugify = function (text) {
  return text
    .toString()
    .toLowerCase()
    .replace(/\s+/g, '-') // Replace spaces with -
    .replace(/[^\w-]+/g, '') // Remove all non-word chars
    .replace(/--+/g, '-') // Replace multiple - with single -
    .replace(/^-+/, '') // Trim - from start of text
    .replace(/-+$/, '') // Trim - from end of text
}

const createList = ({ list, separator = ',' }) => {
  if (!list) return

  return list.map((text, index) => {
    let sep

    if (list.length !== index + 1) {
      sep = separator
    }

    return { text, sep }
  })
}

const flatDeep = (arr, d = 1) => {
  return d > 0
    ? arr.reduce(
        (acc, val) =>
          acc.concat(Array.isArray(val) ? flatDeep(val, d - 1) : val),
        [],
      )
    : arr.slice()
}

function truncateString(str, num, dots = true) {
  let endDots = dots ? '...' : ''

  if (str.length <= num) {
    return str
  }

  return str.slice(0, num) + endDots
}

// Taken from https://stackoverflow.com/questions/15900485/correct-way-to-convert-size-in-bytes-to-kb-mb-gb-in-javascript
function formatBytes(bytes, decimals = 2) {
  if (bytes === 0) return '0 Bytes'

  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['bytes', 'kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

  const i = Math.floor(Math.log(bytes) / Math.log(k))

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
}

module.exports = {
  slugify,
  getSiblings,
  getClosest,
  createList,
  flatDeep,
  truncateString,
  formatBytes,
}
