import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'
import { slugify } from '../../../utils/utilFunctions'

const MenuData = ({ menu }) => {
  const menuData = useStaticQuery(graphql`
    query MenuQuery {
      themePages: allContentfulThema(sort: { fields: title }) {
        edges {
          node {
            id
            title
            url
          }
        }
      }

      subsidyPages: allContentfulSubsidieAanvraag {
        edges {
          node {
            id
            title
            slug
          }
        }
      }

      subthemePages: allContentfulOnderwerp {
        edges {
          node {
            id
            title
            slug
            themes {
              title
            }
          }
        }
      }

      regionPages: allContentfulPagina(
        filter: { id: { eq: "e9043380-b7da-53da-b06b-7e953c9543b3" } }
      ) {
        edges {
          node {
            id
            subpages {
              ... on Node {
                ... on ContentfulPagina {
                  id
                  name
                }
              }
            }
          }
        }
      }

      rwbPages: allContentfulPagina(
        filter: { id: { eq: "5f9d4fff-0546-5a90-82cf-3cb79d9579b2" } }
      ) {
        edges {
          node {
            id
            subpages {
              ... on Node {
                ... on ContentfulPagina {
                  id
                  name
                }
              }
            }
          }
        }
      }



      regioDeal: allContentfulOnderwerp(
        filter: { id: { eq: "aa9a888b-c775-50b5-9ff0-f703a4a0738e" } }
      ) {
        edges {
          node {
            id          
          }
        }
      }
    
    }
  `)

  const themeMenu = {
    id: 'themes-id',
    text: "Thema's",
    link: '/themas',
    isSubmenu: true,
    megamenu: menuData.themePages.edges.map(({ node }) => {
      return {
        id: node.id,
        title: node.title,
        link: `/themas/${node.url}`,
        submenu: menuData.subthemePages.edges
          .filter(
            (edge) =>
              edge.node.themes &&
              Array.isArray(edge.node.themes) &&
              edge.node.themes.filter(current => current.title === node.title) > 0
          )
          .map((edge) => {
            return {
              text: edge.node.title,
              link: `/${edge.node.slug}`,
            }
          }),
      }
    }),
  }

  // @to-do: Make dynamic
  themeMenu.megamenu.push({
    id: 'other-projects-id',
    title: 'Overige projecten',
    link: '/themas/projecten',
    submenu: [
      {
        id: 'all-projects-id',
        text: 'Project overzicht',
        link: '/themas/projecten',
      },
    ],
  })

  const topicalMenu = {
    id: 'topical-id',
    text: 'Actueel',
    link: '/actueel',
    isSubmenu: true,
    submenu: [
      {
        text: 'Nieuws',
        link: '/actueel/nieuws',
      },
      {
        text: 'Publicaties',
        link: '/actueel/publicaties',
      },
      {
        text: 'Agenda',
        link: '/actueel/agenda',
      },
      {
        text: 'Dataloket',
        link: '/actueel/dataloket-west-brabant',
      },
      {
        text: 'Nieuwsbrief',
        link: '/actueel/nieuwsbrief',
      },
    ],
  }

  const subsidyMenu = {
    id: 'subsidy-id',
    text: 'Subsidieloket',
    link: '/subsidieloket',
    isSubmenu: true,
    submenu: menuData.subsidyPages.edges.map(({ node }) => {
      return {
        id: node.id,
        text: node.title,
        link: `/subsidieloket/${node.slug}`,
      }
    }),
  }

  const regionMenu = {
    id: menuData.regionPages.edges[0].node.id,
    text: 'Over de regio',
    link: '/over-de-regio',
    isSubmenu: true,
    submenu: menuData.regionPages.edges[0].node.subpages.map(({ id, name }) => {
      return {
        id,
        text: name,
        link: `/over-de-regio/${slugify(name)}`,
      }
    }),
  }

  const rwbMenu = {
    id: menuData.rwbPages.edges[0].node.id,
    text: 'Over RWB',
    link: '/over-rwb',
    isSubmenu: true,
    submenu: menuData.rwbPages.edges[0].node.subpages.map(({ id, name }) => {
      return {
        id,
        text: name,
        link: `/over-rwb/${slugify(name)}`,
      }
    }),
  }

  // @to-do: Make dynamic
  rwbMenu.submenu.push({
    id: 'people-list-id',
    text: 'De mensen',
    link: '/over-rwb/de-mensen',
  })

  const regioDealMenu = menuData.regioDeal.edges[0]?.node?.id ? {
    id: menuData.regioDeal.edges[0].node.id,
    text: 'Regio deal',
    link: '/onderwerp/regiodeal',
    isSubmenu: true,
    submenu: [],
  } : undefined

  switch (menu) {
    case 'topical':
      return topicalMenu
    case 'theme':
      return themeMenu
    case 'subsidy':
      return subsidyMenu
    case 'region':
      return regionMenu
    case 'rwb':
      return rwbMenu
    default:
      return [topicalMenu, themeMenu, subsidyMenu, regionMenu, rwbMenu, regioDealMenu].filter(x => x !== undefined)
  }
}

MenuData.propTypes = {
  menu: PropTypes.oneOf([
    'all',
    'topical',
    'theme',
    'subsidy',
    'region',
    'rwb',
  ]),
}

MenuData.defaultProps = {
  menu: 'all',
}

export default MenuData
