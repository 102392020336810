import styled from 'styled-components'

export const OffCanvasBodyWrap = styled.div`
  margin-top: 20px;
  margin-right: 0;
  margin-bottom: 100px;
  margin-left: 0;
  overflow: hidden;
  padding: 20px 30px 100px;
`
