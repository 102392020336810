import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { truncateString } from '../utils/utilFunctions'

const SEO = ({ metadata, title, description, image, pathname, url, type }) => {
  const siteUrl = metadata ? metadata.siteUrl : 'https://www.west-brabant.eu/'
  const fullUrl = url
    ? url
    : metadata && metadata.url
    ? metadata.url
    : `${siteUrl}${pathname || '/'}`
  const seo = {
    titleTemplate:
      metadata && metadata.titleTemplate ? metadata.titleTemplate : 'RWB',
    title: title || metadata.defaultTitle,
    description: description
      ? truncateString(description, 155)
      : metadata.defaultDescription,
    image: image
      ? `https:${image}`
      : metadata
      ? `${siteUrl}${metadata.defaultImage}`
      : '',
    url: fullUrl,
    type: type || 'website',
  }

  return (
    <Helmet
      title={seo.titleTemplate}
      titleTemplate={`%s | ${seo.title}`}
      defer={false}
    >
      <html lang="nl" />
      <meta name="description" content={seo.description} />
      <meta name="image" content={seo.image} />
      <meta
        prefix="og: http://ogp.me/ns#"
        property="og:type"
        content={seo.type}
      />
      <meta
        prefix="og: http://ogp.me/ns#"
        property="og:image:width"
        content="400"
      />
      <meta
        prefix="og: http://ogp.me/ns#"
        property="og:image:height"
        content="300"
      />
      {seo.url && (
        <meta
          prefix="og: http://ogp.me/ns#"
          property="og:url"
          content={seo.url}
        />
      )}
      {seo.title && (
        <meta
          prefix="og: http://ogp.me/ns#"
          property="og:title"
          content={seo.title}
        />
      )}
      {seo.description && (
        <meta
          prefix="og: http://ogp.me/ns#"
          property="og:description"
          content={seo.description}
        />
      )}
      {seo.image && (
        <meta
          prefix="og: http://ogp.me/ns#"
          property="og:image"
          content={seo.image}
        />
      )}
      <meta name="twitter:card" content="summary_large_image" />
      {seo.title && <meta name="twitter:title" content={seo.title} />}
      {seo.description && (
        <meta name="twitter:description" content={seo.description} />
      )}
      {seo.image && <meta name="twitter:image" content={seo.image} />}

      <link rel="canonical" href={seo.url} />
    </Helmet>
  )
}

export default SEO

SEO.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  pathname: PropTypes.string,
  article: PropTypes.bool,
  metadata: PropTypes.object,
}

SEO.defaultProps = {
  title: null,
  description: null,
  image: null,
  pathname: null,
  article: false,
}
