import React, { useState } from 'react'
import { MdExpandMore } from 'react-icons/md'

import {
  Menu,
  MenuButton,
  MenuButtonIconWrapper,
  MenuButtonText,
  MenuItem,
  MenuLink,
  SubMenu,
  SubMenuItem,
} from './mobilemenu.style'

const AccordionMenu = ({ menuData, isNested }) => {
  const [openPanel, setOpenPanel] = useState(null)

  return (
    <Menu>
      {menuData.map(({ id, link, submenu, megamenu, text, title }) => (
        <MenuItem key={id} isNested={isNested}>
          <MenuButton
            aria-controls={id}
            aria-expanded={openPanel === id}
            aria-haspopup="menu"
            id={`${id}-accordion`}
            isNested={isNested}
            onClick={() =>
              setOpenPanel((prevId) => (prevId === id ? null : id))
            }
            type="button"
          >
            <MenuButtonText
              isActive={
                typeof window !== 'undefined'
                  ? window.location.pathname.includes(link)
                  : false
              }
            >
              {text || title}
            </MenuButtonText>
            <MenuButtonIconWrapper
              isExpanded={openPanel === id}
              isNested={isNested}
            >
              <MdExpandMore
                arial-label={`${text || title} Menu Icoon`}
                role="img"
                title={`${text || title} Menu Icoon`}
              />
            </MenuButtonIconWrapper>
          </MenuButton>
          <SubMenu
            aria-hidden={openPanel !== id}
            aria-labelledby={`${id}-accordion`}
            id={id}
            isExpanded={openPanel === id}
            onFocus={() => setOpenPanel(id)}
            role="menu"
          >
            <SubMenuItem role="presentation">
              <MenuLink
                activeClassName="active"
                partiallyActive={
                  typeof window !== 'undefined'
                    ? window.location.pathname === link
                    : false
                }
                role="menuitem"
                to={link}
              >
                Ga naar {text || title}
              </MenuLink>
            </SubMenuItem>
            {submenu &&
              submenu.map(({ id, link, text }, index) => (
                <SubMenuItem key={id || index} role="presentation">
                  <MenuLink
                    activeClassName="active"
                    partiallyActive={
                      typeof window !== 'undefined'
                        ? window.location.pathname.includes(link)
                        : false
                    }
                    role="menuitem"
                    to={link}
                  >
                    {text}
                  </MenuLink>
                </SubMenuItem>
              ))}
            {megamenu && openPanel === id && (
              <AccordionMenu menuData={megamenu} isNested />
            )}
          </SubMenu>
        </MenuItem>
      ))}
    </Menu>
  )
}

export const MobileMenu = ({ menuData }) => (
  <nav>
    <AccordionMenu menuData={menuData} />
  </nav>
)
