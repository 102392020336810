import React, { forwardRef } from 'react'
import { OffCanvasHeader } from './off-canvas-header'
import { OffCanvasBody } from './off-canvas-body'
import { OffCanvaseWrap, OffCanvasInner } from './off-canvas.style'

const OffCanvas = forwardRef(({ children, onClick, ...props }, ref) => {
  return (
    <OffCanvaseWrap {...props} onClick={onClick}>
      <OffCanvasInner {...props} onClick={(e) => e.stopPropagation()} ref={ref}>
        {children}
      </OffCanvasInner>
    </OffCanvaseWrap>
  )
})

export { OffCanvasHeader, OffCanvasBody }
export default OffCanvas
