import React from 'react'
import PropTypes from 'prop-types'
import { CloseButtonWrap } from './close-button.style'

const CloseButton = ({ onClick, color, ...restProps }) => {
  return (
    <CloseButtonWrap
      onClick={onClick}
      color={color}
      aria-label="Sluiten"
      {...restProps}
    >
      <span></span>
    </CloseButtonWrap>
  )
}

CloseButton.propTypes = {
  onClick: PropTypes.func,
  color: PropTypes.string,
}

CloseButton.defaultProps = {
  color: '#333',
}

export default CloseButton
