import styled, { css } from 'styled-components'
import { Swiper, SwiperSlide } from 'swiper/react'
import { device } from '../../../theme'

export const NextButton = styled.button(
  ({ theme: { colors } }) => css`
    background: ${colors.white};
    border: 0px;
    border-radius: 50%;
    color: ${colors.black};
    height: 48px;
    right: 10px;
    line-height: 30px;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    top: calc(50% - 24px);
    width: 48px;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
    z-index: 1;

    :hover {
      background: ${colors.themeColor};
      color: ${colors.white};
    }
  `,
)
export const PreviousButton = styled(NextButton)`
  left: 10px;
  right: auto;
`
export const SwiperWrap = styled.div(({ offset, theme: { colors } }) => [
  css`
    position: relative;

    :hover {
      ${NextButton},
      ${PreviousButton} {
        opacity: 1;
        pointer-events: all;
      }
    }
  `,

  offset &&
    css`
      @media ${device.largeOnly} {
        margin: -80px 0 0;
        padding: 50px 40px 0;
        background-color: ${colors.background};
      }
    `,
])
export const StyledSwiper = styled(Swiper)``

export const StyledSwiperSlide = styled(SwiperSlide)`
  height: auto;

  .item {
    height: 100%;
  }
`

export const SwiperPagination = styled.div(
  ({ theme: { transition, colors } }) => css`
    display: flex;
    justify-content: center;
    line-height: 1;
    margin-top: 20px;
    position: static;

    align-items: center;

    .swiper-pagination-bullet {
      background: transparent;
      border: 0;
      border-radius: 0;
      cursor: pointer;
      height: 12px;
      margin: 0 4px;
      opacity: 1;
      outline: none;
      position: relative;
      width: 12px;

      &::before {
        background: #d8d8d8;
        border-radius: 100%;
        content: '';
        height: 8px;
        left: 50%;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        transition: ${transition};
        width: 8px;
        z-index: 1;
      }

      &:hover::before {
        background: ${colors.themeColor};
        height: 100%;
        width: 100%;
      }

      &.swiper-pagination-bullet-active::before {
        background: ${colors.themeColor};
        height: 100%;
        width: 100%;
      }
    }
  `,
)
