import styled, { css } from 'styled-components'
import { Link } from 'gatsby'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import { device } from '../../../../theme'

export const NavLinkStyled = css`
  display: inline-block;
  position: relative;
  color: #444;
  font-family: ${(props) => props.theme.fontFamily.heading};
  font-size: 16px;
  line-height: 1.18;
  padding-right: 40px;
  text-transform: uppercase;

  ${(props) =>
    props.hassubmenu === 'false' &&
    css`
      padding: 0;
    `}

  ~ ul li a {
    font-family: ${(props) => props.theme.fontFamily.text};
    text-transform: none;
  }

  &::before {
    position: absolute;
    content: '';
    left: 0;
    top: 0%;
    width: 100%;
    height: 100%;
  }

  &.active {
    &,
    span {
      color: ${(props) => props.theme.colors.theme};
    }

    span::before {
      width: 100%;
      left: 0;
    }
  }

  span {
    display: inline-block;
    position: relative;
    padding: 22px 0;

    &::before {
      content: '';
      width: 0;
      height: 3px;
      bottom: 0;
      position: absolute;
      left: 0;
      background-color: ${(props) => props.theme.colors.theme};
      transition: ${(props) => props.theme.transition};
    }
  }

  & > .icon {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 23px;
    height: 23px;

    @media ${device.mediumOnly} {
      display: none;
    }
  }
`

export const AnchorTag = styled.a`
  ${NavLinkStyled}
`

export const NavLinkWrap = styled(Link)`
  ${NavLinkStyled}
`

export const SmoothScroll = styled(AnchorLink)`
  ${NavLinkStyled}
`
