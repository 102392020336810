import styled from 'styled-components'
import { device } from '../../theme'

export const Quote = styled.blockquote`
  position: relative;
  margin: 40px 0 60px;
  padding-left: 134px;
  font-size: 30px;
  line-height: 40px;

  @media ${device.medium} {
    padding-left: 0;
    font-size: 24px;
  }

  small {
    font-size: 18px;
    line-height: 26px;
  }

  .quote-text > p {
    quotes: '“' '”' '‘' '’';

    &:first-of-type::before {
      content: open-quote;
    }

    &:last-of-type::after {
      content: close-quote;
    }
  }

  .quote-icon {
    display: block;
    position: absolute;
    top: 50%;
    left: 0;
    height: 96px;
    width: 96px;
    line-height: 96px;
    border-radius: 96px;
    font-size: 32px;
    color: ${(props) => props.theme.colors.white};
    background-color: #d7d7d7;
    text-align: center;
    transform: translateY(-50%);

    @media ${device.medium} {
      display: none;
    }

    svg {
      position: relative;
      top: -4px;
    }
  }
`
