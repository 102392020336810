import React from 'react'
import PropTypes from 'prop-types'
import { TextWrapper } from './text.style'
import RichText from '../richt-text'

const Text = ({ json, as, children, ...props }) => (
  <TextWrapper as={json ? 'div' : as} {...props}>
    {json ? <RichText json={json} /> : children}
  </TextWrapper>
)

Text.propTypes = {
  children: PropTypes.node,
  color: PropTypes.string,
  fontSize: PropTypes.string,
  align: PropTypes.string,
  json: PropTypes.object,
  lineHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  pt: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  pb: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  mt: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  mb: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  maxWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  fontWeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  as: PropTypes.oneOf(['p', 'a', 'span', 'strong', 'em', 'small', 'div']),
  responsive: PropTypes.object,
}

Text.defaultProps = {
  as: 'p',
}

export default Text
