import styled, { css } from 'styled-components'
import Logo from '../../components/logo'
import { Row } from '../../components/ui/wrapper'
import Anchor from '../../components/ui/anchor'
import { device } from '../../theme'
import LogoBackground from '../../assets/images/logo/logo-background.svg'

export const FooterWrap = styled.footer`
  position: relative;
  background-color: ${(props) => props.bgcolor};
  margin-top: 80px;

  ${(props) =>
    props.reveal === 'true' &&
    css`
      position: fixed;
      bottom: 0;
      left: 0;
      width: 100%;
      z-index: -1;
    `};

  @media print {
    display: none;
  }
`

export const FooterAnchor = styled(Anchor)`
  :hover {
    text-decoration: underline;
  }
`

export const FooterTop = styled.div`
  padding-top: 80px;
  padding-bottom: 80px;
  background-color: ${(props) => props.theme.colors.backgroundDark};

  background-image: url(${LogoBackground});
  background-repeat: no-repeat;
  background-position: bottom -50px right calc((100% - 1000px) / 2);
  background-size: 550px;

  @media ${device.large} {
    background-position: bottom -50px right -50px;
  }

  @media ${device.medium} {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  @media ${device.small} {
    padding-top: 40px;
    padding-bottom: 40px;
  }
`

export const FooterLogo = styled(Logo)`
  position: absolute;
  top: -80px;
  transform: translateY(-50%);
  overflow: hidden;

  svg {
    height: 90px;
    width: auto;
    margin-left: -20px;
  }

  @media ${device.medium} {
    top: -60px;
  }

  @media ${device.small} {
    display: none;
  }
`

export const FooterTopRow = styled(Row)`
  @media ${device.xsmallOnly} and ${device.medium} {
    > * {
      min-width: 50%;
      max-width: 50%;
      width: 50%;
      margin-bottom: 40px;
    }
  }

  @media ${device.xsmall} {
    display: block;
  }
`

export const FooterBottomRow = styled(Row)`
  border-top: 1px solid ${(props) => props.theme.colors.borderLight};
  margin-top: 40px;
  padding-top: 26px;

  @media ${device.xsmallOnly} and ${device.medium} {
    > * {
      min-width: 50%;
      max-width: 50%;
      width: 50%;
      margin-bottom: 40px;
    }
  }

  @media ${device.xsmall} {
    display: block;

    a {
      width: 100%;
    }
  }
`

export const FooterBottom = styled.div`
  padding: 15px 0;

  .align-items-center p {
    padding: 0;
    margin-top: 0;
    margin-bottom: 0;

    @media ${device.smallOnly} {
      float: left;
      margin-right: 40px;
    }
  }
`

export const FooterWidget = styled.div`
  margin-top: ${(props) => props.mt};
  margin-bottom: ${(props) => props.mb};
  margin-left: ${(props) => props.ml};
  margin-right: ${(props) => props.mr};

  h3 {
    font-size: ${(props) => props.theme.fontSizes.h6};
    line-height: ${(props) => props.theme.lineHieghts.h6};
  }

  ${(props) =>
    props.responsive &&
    css`
      ${(props) =>
        props.responsive.large &&
        css`
          @media ${device.large} {
            margin-top: ${(props) => props.responsive.large.mt};
            margin-bottom: ${(props) => props.responsive.large.mb};
            margin-left: ${(props) => props.responsive.large.ml};
            margin-right: ${(props) => props.responsive.large.mr};
          }
        `}

      ${(props) =>
        props.responsive.medium &&
        css`
          @media ${device.medium} {
            margin-top: ${(props) => props.responsive.medium.mt};
            margin-bottom: ${(props) => props.responsive.medium.mb};
            margin-left: ${(props) => props.responsive.medium.ml};
            margin-right: ${(props) => props.responsive.medium.mr};
          }
        `}

        ${(props) =>
        props.responsive.small &&
        css`
          @media ${device.small} {
            margin-top: ${(props) => props.responsive.small.mt};
            margin-bottom: ${(props) => props.responsive.small.mb};
            margin-left: ${(props) => props.responsive.small.ml};
            margin-right: ${(props) => props.responsive.small.mr};
          }
        `}

        ${(props) =>
        props.responsive.xsmall &&
        css`
          @media ${device.xsmall} {
            margin-top: ${(props) => props.responsive.xsmall.mt};
            margin-bottom: ${(props) => props.responsive.xsmall.mb};
            margin-left: ${(props) => props.responsive.xsmall.ml};
            margin-right: ${(props) => props.responsive.xsmall.mr};
          }
        `}
    `}
`

export const LogoWidget = styled.figure`
  margin-bottom: 30px;
`

export const TextWidget = styled.div``

export const FooterWidgetList = styled.ul`
  li {
    font-size: ${(props) => props.theme.fontSizes.text};

    a {
      opacity: 0.7;
      transition: ${(props) => props.theme.transition};

      &:hover {
        color: ${(props) => props.theme.colors.white};
        opacity: 1;
      }
    }

    &:not(:last-child) {
      margin-bottom: 10px;
    }
  }
`

export const FooterBottomList = styled.ul`
  display: flex;

  @media ${device.large} {
    margin: 10px 0 40px;
  }

  @media ${device.medium} {
    justify-content: center;
  }

  @media ${device.xxsmall} {
    flex-direction: column;

    li:not(:last-child) {
      margin-bottom: 15px;
    }
  }

  @media ${device.xxsmallOnly} {
    li:not(:last-child) {
      margin-right: 30px;
    }
  }

  a {
    color: ${(props) => props.theme.colors.text};
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`

export const FooterCopyright = styled.div`
  @media ${device.large} {
    margin-top: 10px;

    p {
      float: none !important;
      margin-right: 0 !important;
    }
  }
`

export const FooterBottomContentWrapper = styled.div`
  display: flex;
  flex-direction: row;

  @media ${device.large} {
    flex-direction: column;
    align-items: center;
  }
`
