import styled, { css } from 'styled-components'

export const LogoWrapper = styled.div`
  display: flex;
  justify-content: ${(props) => props.justifycontent};
  padding-top: ${(props) => props.pt};
  padding-bottom: ${(props) => props.pb};

  ${(props) =>
    props.type === 'icon' &&
    css`
      .icon {
        transform: translateX(-10px);
      }
    `}
`
