import React from 'react'
import PropTypes from 'prop-types'
import { INLINES, BLOCKS } from '@contentful/rich-text-types'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { graphql, StaticQuery } from 'gatsby'
import { GoQuote } from 'react-icons/go'
import QuoteSlider from '../../../containers/quote-slider'
import WebsiteList from '../../../containers/website-list'
import Anchor from '../anchor'

const RichText = ({ json }) => {
  const options = {
    renderText: (text) =>
      text.split('\n').flatMap((text, index) => {
        return [index > 0 && <br key={index} />, text]
      }),
    renderNode: {
      [BLOCKS.EMBEDDED_ENTRY]: (node) => {
        const target = node.data.target
        const type = target.sys.contentType.sys.id
        const id = target.sys.id

        if (type === 'quote') {
          const { name, content } = target.fields
          const quotes = [
            {
              id: id,
              name: name && name['nl-NL'] ? name['nl-NL'] : '',
              content:
                content && content['nl-NL']
                  ? { json: content['nl-NL'] }
                  : { json: {} },
            },
          ]

          return <QuoteSlider quotes={quotes} inline={true} />
        }

        if (type === 'websiteLijst') {
          const { title, websites } = target.fields
          const websitesProps = {
            id: id,
            title: title && title['nl-NL'] ? title['nl-NL'] : '',
            websites:
              websites && websites['nl-NL']
                ? websites['nl-NL'].map((site) => {
                    const id = site.sys.id
                    const { title, url, image } = site.fields

                    return {
                      id: id,
                      title: title && title['nl-NL'] ? title['nl-NL'] : '',
                      image:
                        image && image['nl-NL']
                          ? image['nl-NL'].fields.file['nl-NL'].url
                          : '',
                      url: url && url['nl-NL'] ? url['nl-NL'] : '',
                    }
                  })
                : [],
          }

          return <WebsiteList hideTitle {...websitesProps} />
        }
        return <></>
      },
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <p
          className={
            node.content.filter(
              (item) => item.nodeType === 'text' && item.value !== '',
            ).length > 0
              ? ''
              : 'no-text'
          }
        >
          {children}
        </p>
      ),
      [BLOCKS.QUOTE]: (node, children) => (
        <blockquote>
          <div className="quote-icon">
            <GoQuote role="img" />
          </div>
          {children}
        </blockquote>
      ),
      [BLOCKS.EMBEDDED_ASSET]: ({ data }) => {
        const fields = data.target.fields
        const title = fields && fields.title ? fields.title['nl-NL'] : ''
        const description =
          fields && fields.description ? fields.description['nl-NL'] : ''
        const file = fields && fields.file ? fields.file['nl-NL'] : ''
        const contentType = file ? file.contentType : ''

        return (
          <>
            {file && contentType.includes('image') && (
              <a href={file.url} target="_blank" rel="noreferrer">
                <img src={file.url} alt={title} title={description || title} />
              </a>
            )}
          </>
        )
      },
      [INLINES.HYPERLINK]: (node, children) => (
        <Anchor path={node.data.uri}>{children}</Anchor>
      ),
      [INLINES.ASSET_HYPERLINK]: (node, children) => (
        <StaticQuery
          query={graphql`
            query {
              assets: allContentfulAsset(
                filter: { file: { contentType: { regex: "/application/" } } }
              ) {
                edges {
                  node {
                    contentful_id
                    file {
                      fileName
                      url
                    }
                  }
                }
              }
            }
          `}
          render={(data) => {
            const file = data.assets.edges.map((edge) => {
              return (
                edge.node.contentful_id ===
                  node.data.target.sys.contentful_id && edge.node.file
              )
            })

            return file.map((download, index) => {
              return (
                download && (
                  <Anchor
                    key={index}
                    path={download.url}
                    title={download.fileName}
                    download
                  >
                    {children}
                  </Anchor>
                )
              )
            })
          }}
        />
      ),
    },
  }

  return <>{documentToReactComponents(json, options)}</>
}

RichText.propTypes = {
  json: PropTypes.object.isRequired,
}

export default RichText
