import React from 'react'
import PropTypes from 'prop-types'
import Heading from '../../components/ui/heading'
import BoxLargeImage from '../../components/box-large-image/layout-two'
import { Row, Col } from '../../components/ui/wrapper'

const WebsiteList = ({ title, hideTitle, websites }) => (
  <>
    {!hideTitle && (
      <Row>
        <Col md={12}>
          <Heading as="h3">{title}</Heading>
        </Col>
      </Row>
    )}

    <Row mb="20px">
      {websites.map(({ id, title, url, image }) => (
        <Col md={6} className="box-item" key={`website-${id}`} mb="30px">
          <BoxLargeImage
            title={title}
            path={url}
            btnText="Bekijk website"
            imageSrc={image}
          />
        </Col>
      ))}
    </Row>
  </>
)

WebsiteList.propTypes = {
  title: PropTypes.string.isRequired,
  hideTitle: PropTypes.bool,
  websites: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
      image: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    }),
  ).isRequired,
}

WebsiteList.defaultProps = {
  title: 'Websites',
  hideTitle: false,
}

export default WebsiteList
