import React from 'react'
import PropTypes from 'prop-types'
import { graphql, useStaticQuery } from 'gatsby'
import Social, { SocialLink } from '../'

import {
  FaTwitter,
  FaFacebookF,
  FaWhatsapp,
  FaLinkedinIn,
  FaRegEnvelope,
} from 'react-icons/fa'

import { FiPrinter } from 'react-icons/fi'

const SocialShare = ({
  url,
  title,
  description,
  linkedin,
  twitter,
  facebook,
  whatsapp,
  mail,
  print,
  ...rest
}) => {
  const SocialData = useStaticQuery(graphql`
    query SocialData {
      site {
        siteMetadata {
          siteUrl
        }
      }

      socials: allContentfulBedrijf(
        filter: { id: { eq: "ca7bb2ca-5d8a-531e-a88e-80dc8c9e272b" } }
      ) {
        edges {
          node {
            twitter
          }
        }
      }
    }
  `)

  const socials = SocialData.socials.edges[0].node
  const shareUrl =
    url.href || `${SocialData.site.siteMetadata.siteUrl}${url.pathname}`

  return (
    <Social
      space="8px"
      tooltip={true}
      shape="rounded"
      varient="outlined"
      {...rest}
    >
      {mail && (
        <SocialLink
          aria-label={`Deel ${title} via Email`}
          path={`mailto:?subject=${encodeURIComponent(title)}&body=${
            description
              ? `${encodeURIComponent(description)}%0D%0A%0D%0A${shareUrl}`
              : shareUrl
          }`}
          title={`Deel ${title} via Email`}
        >
          <FaRegEnvelope
            aria-label={`Deel ${title} via Email Icoon`}
            role="img"
            title={`Deel ${title} via Email Icoon`}
          />
        </SocialLink>
      )}

      {linkedin && (
        <SocialLink
          aria-label={`Deel ${title} via LinkedIn`}
          path={`https://www.linkedin.com/sharing/share-offsite/?url=${shareUrl}`}
          title={`Deel ${title} via LinkedIn`}
        >
          <FaLinkedinIn
            aria-label={`Deel ${title} via LinkedIn Icoon `}
            role="img"
            title={`Deel ${title} via LinkedIn Icoon `}
          />
        </SocialLink>
      )}

      {facebook && (
        <SocialLink
          aria-label={`Deel ${title} via Facebook`}
          path={`http://www.facebook.com/sharer.php?u=${shareUrl}&t=${encodeURIComponent(
            title,
          )}`}
          title={`Deel ${title} via Facebook`}
        >
          <FaFacebookF
            aria-label={`Deel ${title} via Facebook Icoon `}
            role="img"
            title={`Deel ${title} via Facebook Icoon `}
          />
        </SocialLink>
      )}

      {twitter && (
        <SocialLink
          aria-label={`Deel ${title} via Twitter`}
          path={`https://twitter.com/intent/tweet?text=${encodeURIComponent(
            title,
          )}%0D%0A%0D%0A${shareUrl}&hashtags=RegioWestBrabant${
            socials.twitter
              ? `&via=${socials.twitter.split('twitter.com/')[1]}`
              : ''
          }`}
          title={`Deel ${title} via Twitter`}
        >
          <FaTwitter
            aria-label={`Deel ${title} via Twitter Icoon `}
            role="img"
            title={`Deel ${title} via Twitter Icoon `}
          />
        </SocialLink>
      )}

      {whatsapp && (
        <SocialLink
          aria-label={`Deel ${title} via Whatsapp`}
          path={`https://wa.me/?text=${encodeURIComponent(
            title,
          )}%0D%0A${shareUrl}`}
          title={`Deel ${title} via Whatsapp`}
        >
          <FaWhatsapp
            aria-label={`Deel ${title} via Whatsapp Icoon `}
            role="img"
            title={`Deel ${title} via Whatsapp Icoon `}
          />
        </SocialLink>
      )}

      {print && (
        <SocialLink
          aria-label={`Print ${title}`}
          path="#"
          title={`Print ${title}`}
          onClick={(event) => {
            event.preventDefault()
            window.print()
          }}
        >
          <FiPrinter
            aria-label={`Print ${title} Icoon`}
            role="img"
            title={`Print ${title} Icoon`}
          />
        </SocialLink>
      )}
    </Social>
  )
}

SocialShare.defaultProps = {
  linkedin: true,
  twitter: true,
  facebook: true,
  whatsapp: true,
  mail: true,
  print: true,
}

SocialShare.propTypes = {
  url: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  linkedin: PropTypes.bool,
  twitter: PropTypes.bool,
  facebook: PropTypes.bool,
  whatsapp: PropTypes.bool,
  mail: PropTypes.bool,
  print: PropTypes.bool,
}

export default SocialShare
