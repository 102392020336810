import React from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'
import parse from 'html-react-parser'
import { Container } from 'react-bootstrap'
import { Row, Col } from '../../components/ui/wrapper'
import Text from '../../components/ui/text'

import Button from '../../components/ui/button'
import Heading from '../../components/ui/heading'
import Social, { SocialLink } from '../../components/ui/social'
import { MenuData } from '../../components/menu'

import {
  TiSocialFacebook,
  TiSocialTwitter,
  TiSocialInstagram,
  TiSocialLinkedin,
  TiSocialYoutube,
  TiMail,
  TiDevicePhone,
} from 'react-icons/ti'

import {
  FooterAnchor,
  FooterLogo,
  FooterWrap,
  FooterTop,
  FooterWidget,
  FooterWidgetList,
  FooterTopRow,
  FooterBottom,
  FooterBottomRow,
  FooterBottomList,
  FooterCopyright,
  FooterBottomContentWrapper,
} from './footer.style'

const Footer = ({ copyrightStyle, ...props }) => {
  const siteInfo = useStaticQuery(graphql`
    query FooterSiteQuery {
      company: allContentfulBedrijf(
        filter: { id: { eq: "ca7bb2ca-5d8a-531e-a88e-80dc8c9e272b" } }
      ) {
        edges {
          node {
            name
            addressCity
            addressNumber
            addressPostalCode
            addressStreet
            poBox
            poCity
            poPostalCode
            phone
            email
            linkedIn
            facebook
            twitter
            youtube
            instagram
            archive
          }
        }
      }
    }
  `)

  const {
    name,
    addressStreet,
    addressNumber,
    addressCity,
    addressPostalCode,
    poBox,
    poCity,
    poPostalCode,
    phone,
    email,
    linkedIn,
    facebook,
    twitter,
    youtube,
    instagram,
    archive,
  } = siteInfo.company.edges[0].node

  const topicalMenu = MenuData({ menu: 'topical' })
  const themeMenu = MenuData({ menu: 'theme' })
  const subsidyMenu = MenuData({ menu: 'subsidy' })
  const regionMenu = MenuData({ menu: 'region' })
  const rwbMenu = MenuData({ menu: 'rwb' })

  return (
    <FooterWrap id="footer" {...props}>
      <FooterTop>
        <Container>
          <FooterTopRow>
            <Col>
              <FooterLogo type="icon" />

              {topicalMenu && (
                <FooterWidget responsive={{ medium: { mb: '32px' } }}>
                  <Heading as="h3" color="white" mt="-3px" mb="20px">
                    <FooterAnchor
                      path={topicalMenu.link}
                      color="white"
                      hoverstyle="2"
                      title={`Ga naar de ${topicalMenu.text} pagina`}
                      aria-label={`Ga naar de ${topicalMenu.text} pagina`}
                    >
                      {topicalMenu.text}
                    </FooterAnchor>
                  </Heading>

                  {topicalMenu.submenu && topicalMenu.submenu.length && (
                    <FooterWidgetList>
                      {topicalMenu.submenu.map(({ text, link }, index) => (
                        <li key={`${link}-${index}`}>
                          <FooterAnchor
                            path={link}
                            color="white"
                            hoverstyle="2"
                            title={`Ga naar de ${text} pagina`}
                            aria-label={`Ga naar de ${text} pagina`}
                          >
                            {text}
                          </FooterAnchor>
                        </li>
                      ))}
                    </FooterWidgetList>
                  )}
                </FooterWidget>
              )}
            </Col>

            {themeMenu && (
              <Col>
                <FooterWidget responsive={{ medium: { mb: '32px' } }}>
                  <Heading as="h3" color="white" mt="-3px" mb="20px">
                    <FooterAnchor
                      path={themeMenu.link}
                      color="white"
                      hoverstyle="2"
                      title={`Ga naar de ${themeMenu.text} pagina`}
                      aria-label={`Ga naar de ${themeMenu.text} pagina`}
                    >
                      {themeMenu.text}
                    </FooterAnchor>
                  </Heading>

                  {themeMenu.megamenu && themeMenu.megamenu.length && (
                    <FooterWidgetList>
                      {themeMenu.megamenu.map(({ id, title, link }) => (
                        <li key={`${id}`}>
                          <FooterAnchor
                            path={link}
                            color="white"
                            hoverstyle="2"
                            title={`Ga naar de ${title} pagina`}
                            aria-label={`Ga naar de ${title} pagina`}
                          >
                            {title}
                          </FooterAnchor>
                        </li>
                      ))}
                    </FooterWidgetList>
                  )}
                </FooterWidget>
              </Col>
            )}

            {subsidyMenu && (
              <Col>
                <FooterWidget responsive={{ medium: { mb: '32px' } }}>
                  <Heading as="h3" color="white" mt="-3px" mb="20px">
                    <FooterAnchor
                      path={subsidyMenu.link}
                      color="white"
                      hoverstyle="2"
                      title={`Ga naar de ${subsidyMenu.text} pagina`}
                      aria-label={`Ga naar de ${subsidyMenu.text} pagina`}
                    >
                      {subsidyMenu.text}
                    </FooterAnchor>
                  </Heading>

                  {subsidyMenu.submenu && subsidyMenu.submenu.length && (
                    <FooterWidgetList>
                      {subsidyMenu.submenu.map(({ id, text, link }) => (
                        <li key={`${id}`}>
                          <FooterAnchor
                            path={link}
                            color="white"
                            hoverstyle="2"
                            title={`Ga naar de ${text} pagina`}
                            aria-label={`Ga naar de ${text} pagina`}
                          >
                            {text}
                          </FooterAnchor>
                        </li>
                      ))}
                    </FooterWidgetList>
                  )}
                </FooterWidget>
              </Col>
            )}

            {regionMenu && (
              <Col>
                <FooterWidget responsive={{ medium: { mb: '32px' } }}>
                  <Heading as="h3" color="white" mt="-3px" mb="20px">
                    <FooterAnchor
                      path={regionMenu.link}
                      color="white"
                      hoverstyle="2"
                      title={`Ga naar de ${regionMenu.text} pagina`}
                      aria-label={`Ga naar de ${regionMenu.text} pagina`}
                    >
                      {regionMenu.text}
                    </FooterAnchor>
                  </Heading>

                  {regionMenu.submenu && regionMenu.submenu.length && (
                    <FooterWidgetList>
                      {regionMenu.submenu.map(({ id, text, link }) => (
                        <li key={`${id}`}>
                          <FooterAnchor
                            path={link}
                            color="white"
                            hoverstyle="2"
                            title={`Ga naar de ${text} pagina`}
                            aria-label={`Ga naar de ${text} pagina`}
                          >
                            {text}
                          </FooterAnchor>
                        </li>
                      ))}
                    </FooterWidgetList>
                  )}
                </FooterWidget>
              </Col>
            )}

            {rwbMenu && (
              <Col>
                <FooterWidget responsive={{ medium: { mb: '32px' } }}>
                  <Heading as="h3" color="white" mt="-3px" mb="20px">
                    <FooterAnchor
                      path={rwbMenu.link}
                      color="white"
                      hoverstyle="2"
                      title={`Ga naar de ${rwbMenu.text} pagina`}
                      aria-label={`Ga naar de ${rwbMenu.text} pagina`}
                    >
                      {rwbMenu.text}
                    </FooterAnchor>
                  </Heading>

                  {rwbMenu.submenu && rwbMenu.submenu.length && (
                    <FooterWidgetList>
                      {rwbMenu.submenu.map(({ id, text, link }) => (
                        <li key={`${id}`}>
                          <FooterAnchor
                            path={link}
                            color="white"
                            hoverstyle="2"
                            title={`Ga naar de ${text} pagina`}
                            aria-label={`Ga naar de ${text} pagina`}
                          >
                            {text}
                          </FooterAnchor>
                        </li>
                      ))}
                    </FooterWidgetList>
                  )}
                </FooterWidget>
              </Col>
            )}
          </FooterTopRow>

          <FooterBottomRow>
            {((poPostalCode && poCity) || poBox) && (
              <Col>
                <FooterWidget responsive={{ medium: { mb: '32px' } }}>
                  <Heading as="h3" color="white" mt="-3px" mb="20px">
                    Postadres
                  </Heading>
                  <FooterWidgetList>
                    {poBox && (
                      <li>
                        <Text color="white">{poBox},</Text>
                      </li>
                    )}

                    {poPostalCode && poCity && (
                      <li>
                        <Text color="white">
                          {poPostalCode}, {poCity}
                        </Text>
                      </li>
                    )}
                  </FooterWidgetList>
                </FooterWidget>
              </Col>
            )}

            {((addressStreet && addressNumber) ||
              (addressPostalCode && addressCity)) && (
              <Col>
                <FooterWidget>
                  <Heading as="h3" color="white" mt="-3px" mb="20px">
                    Bezoekadres
                  </Heading>
                  <FooterWidgetList>
                    {addressStreet && addressNumber && (
                      <li>
                        <Text color="white">
                          {addressStreet} {addressNumber},
                        </Text>
                      </li>
                    )}

                    {addressPostalCode && addressCity && (
                      <li>
                        <Text color="white">
                          {addressPostalCode} {addressCity}
                        </Text>
                      </li>
                    )}
                  </FooterWidgetList>
                </FooterWidget>
              </Col>
            )}

            {phone && (
              <Col>
                <FooterWidget mt="44px" responsive={{ small: { mt: '34px' } }}>
                  <Button
                    to={`tel: ${phone}`}
                    skin="light"
                    varient="outlined"
                    iconposition="left"
                    icon={
                      <TiDevicePhone
                        role="img"
                        title="Telefoonnummer"
                        aria-label="Telefoonnummer"
                      />
                    }
                    aria-label="Bel ons"
                    title="Bel ons"
                  >
                    {phone}
                  </Button>
                </FooterWidget>
              </Col>
            )}

            {email && (
              <Col>
                <FooterWidget mt="44px" responsive={{ small: { mt: '34px' } }}>
                  <Button
                    to="/contact"
                    skin="light"
                    varient="outlined"
                    iconposition="left"
                    icon={
                      <TiMail role="img" title="Contact" aria-label="Contact" />
                    }
                    aria-label="Ga naar de contact pagina"
                    title="Ga naar de contact pagina"
                  >
                    Contact
                  </Button>
                </FooterWidget>
              </Col>
            )}

            <Col>
              <span></span>
            </Col>
          </FooterBottomRow>
        </Container>
      </FooterTop>
      <FooterBottom>
        <Container>
          <Row className="align-items-center">
            <Col md={12} xl={8} className="text-center text-xl-left">
              <FooterBottomContentWrapper>
                {name && (
                  <FooterCopyright>
                    <Text>
                      <small>
                        &copy; {parse(name)} {new Date().getFullYear()}
                      </small>
                    </Text>
                  </FooterCopyright>
                )}

                <FooterBottomList bottom>
                  <li>
                    <FooterAnchor
                      path="/privacy-en-cookies"
                      color="white"
                      hoverstyle="2"
                      aria-label="Ga naar de privacy & cookies pagina"
                      title="Ga naar de privacy & cookies pagina"
                    >
                      <small>Privacy & cookies</small>
                    </FooterAnchor>
                  </li>
                  <li>
                    <FooterAnchor
                      path="/disclaimer"
                      color="white"
                      hoverstyle="2"
                      aria-label="Ga naar de disclaimer pagina"
                      title="Ga naar de disclaimer pagina"
                    >
                      <small>Disclaimer</small>
                    </FooterAnchor>
                  </li>
                  {archive && (
                    <li>
                      <FooterAnchor
                        path={archive}
                        color="white"
                        hoverstyle="2"
                        aria-label="Ga naar de archiefweb website"
                        title="Ga naar de archiefweb website"
                      >
                        <small>Archief</small>
                      </FooterAnchor>
                    </li>
                  )}
                  <li>
                    <FooterAnchor
                      path="/toegankelijksheidsverklaring"
                      color="white"
                      hoverstyle="2"
                      aria-label="Ga naar de toegankelijksheidsverklaring pagina"
                      title="Ga naar de toegankelijksheidsverklaring pagina"
                    >
                      <small>Toegankelijksheidsverklaring</small>
                    </FooterAnchor>
                  </li>
                </FooterBottomList>
              </FooterBottomContentWrapper>
            </Col>
            <Col md={12} xl={4} className="text-center text-xl-right">
              <Social
                space="8px"
                tooltip={true}
                shape="rounded"
                varient="outlined"
              >
                {linkedIn && (
                  <SocialLink
                    path={linkedIn}
                    title="Linkedin"
                    aria-label="Open Linkedin in een nieuw tabblad"
                  >
                    <TiSocialLinkedin
                      role="img"
                      title="Linkedin"
                      aria-label="Linkedin"
                    />
                  </SocialLink>
                )}

                {youtube && (
                  <SocialLink
                    path={youtube}
                    title="YouTube"
                    aria-label="Open YouTube in een nieuw tabblad"
                  >
                    <TiSocialYoutube
                      role="img"
                      title="YouTube"
                      aria-label="YouTube"
                    />
                  </SocialLink>
                )}

                {twitter && (
                  <SocialLink
                    path={twitter}
                    title="Twitter"
                    aria-label="Open Twitter in een nieuw tabblad"
                  >
                    <TiSocialTwitter
                      role="img"
                      title="Twitter"
                      aria-label="Twitter"
                    />
                  </SocialLink>
                )}

                {facebook && (
                  <SocialLink
                    path={facebook}
                    title="Facebook"
                    aria-label="Open Facebook in een nieuw tabblad"
                  >
                    <TiSocialFacebook
                      role="img"
                      title="Facebook"
                      aria-label="Facebook"
                    />
                  </SocialLink>
                )}

                {instagram && (
                  <SocialLink
                    path={instagram}
                    title="Instagram"
                    aria-label="Open Instagram in een nieuw tabblad"
                  >
                    <TiSocialInstagram
                      role="img"
                      title="Instagram"
                      aria-label="Instagram"
                    />
                  </SocialLink>
                )}
              </Social>
            </Col>
          </Row>
        </Container>
      </FooterBottom>
    </FooterWrap>
  )
}

Footer.propTypes = {
  bgcolor: PropTypes.string,
  reveal: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

Footer.defaultProps = {
  bgcolor: '#F8F8F8',
  reveal: 'false',
}

export default Footer
