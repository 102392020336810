import React from 'react'
import PropTypes from 'prop-types'
import Text from '../../components/ui/text'
import SwiperSlider from '../../components/ui/swiper'
import { GoQuote } from 'react-icons/go'
import { Container, Row, Col } from '../../components/ui/wrapper'
import { Quote } from './style'

const QuoteSlider = ({ quotes, inline }) => {
  const Wrapper = ({ children }) =>
    Array.isArray(quotes) && quotes.length > 1 ? (
      <SwiperSlider slidesPerView={1}>{children}</SwiperSlider>
    ) : (
      <>{children}</>
    )

  const ContainerWrapper = ({ children }) => {
    if (inline) {
      return <>{children}</>
    } else {
      return (
        <Container mb="80px" mt="80px">
          <Wrapper>{children}</Wrapper>
        </Container>
      )
    }
  }

  return (
    <ContainerWrapper>
      {quotes &&
        quotes.map(({ id, name, content }) => (
          <Container key={`quote-slider-${id}`} px={inline ? 0 : 1}>
            <Row alignitems="center">
              <Col md={12}>
                <Quote>
                  <div className="quote-icon">
                    <GoQuote role="img" />
                  </div>
                  <Text className="quote-text" json={content.json} />
                  <Text as="small">— {name}</Text>
                </Quote>
              </Col>
            </Row>
          </Container>
        ))}
    </ContainerWrapper>
  )
}

QuoteSlider.propTypes = {
  inline: PropTypes.bool,
  quotes: PropTypes.oneOfType([
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      content: PropTypes.object,
    }),
    PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
        content: PropTypes.object,
      }),
    ),
  ]),
}

QuoteSlider.defaultProps = {
  inline: false,
}

export default QuoteSlider
