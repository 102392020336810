import styled, { css } from 'styled-components'

export const SocialLinkWrap = styled.a`
  color: ${(props) => props.theme.colors.textColor};
  position: relative;
  display: inline-block;
  &:not(:last-child) {
    margin-right: ${(props) => props.space};
  }
  ${(props) =>
    props.size === 'small' &&
    css`
      svg {
        height: 16px;
        width: 16px;
      }
    `}
  ${(props) =>
    props.size === 'medium' &&
    css`
      svg {
        width: 20px;
        height: 20px;
      }
    `}
    ${(props) =>
    props.size === 'large' &&
    css`
      svg {
        width: 25px;
        height: 25px;
      }
    `}
    ${(props) =>
    props.varient === 'flat' &&
    css`
      height: 48px;
      width: 48px;
      text-align: center;
      line-height: 48px;
      background: #ededed;
      svg {
        width: 18px;
        height: 18px;
      }
      &:hover {
        background: ${(props) => props.theme.colors.themeColor};
        color: #fff;
      }
    `}
    ${(props) =>
    props.varient === 'outlined' &&
    css`
      height: 48px;
      width: 48px;
      text-align: center;
      line-height: 46px;
      background: transparent;
      border: 1px solid ${(props) => props.theme.colors.borderColor};
      svg {
        width: 18px;
        height: 18px;
      }
      &:hover {
        background: ${(props) => props.theme.colors.themeColor};
        color: #fff;
      }
      ${(props) =>
        props.skin === 'light' &&
        css`
          border-color: #fff;
          &:hover {
            color: ${(props) => props.theme.colors.themeColor};
            background: #fff;
          }
        `}
    `}
    ${(props) =>
    props.varient === 'texted' &&
    css`
      padding: 5px 8px;
      margin-right: 0 !important;
      color: #333;
      &:not(:first-child) {
        &:before {
          content: '-';
          position: absolute;
          top: 50%;
          left: -5px;
          transform: translateY(-50%);
          color: #333;
        }
      }
      ${(props) =>
        props.skin === 'light' &&
        css`
          &:not(:first-child) {
            &:before {
              color: #ffffff;
            }
          }
          &:hover {
            color: #ffffff;
          }
        `}
    `}
    ${(props) =>
    props.shape === 'rounded' &&
    css`
      border-radius: 50%;
    `}
    ${(props) =>
    props.skin === 'light' &&
    css`
      color: #fff;
    `}
    ${(props) =>
    props.hover &&
    css`
      &:hover {
        color: ${(props) => props.hover.color};
      }
    `}
`
