import styled from 'styled-components'

export const ExpandButtonWrap = styled.span`
  display: block;
  width: 40px;
  height: 40px;
  border-radius: 40px;
  background: rgba(0, 0, 0, 0.1);
  color: ${(props) => props.theme.colors.text};
  text-align: center;
  line-height: 40px;
  cursor: pointer;
  transition: ${(props) => props.theme.transition};

  &.submenu-open .icon {
    transform: rotate(-180deg);
  }

  .icon {
    font-size: 25px;
    width: 25px;
    line-height: 40px;
    transition: ${(props) => props.theme.transition};
  }
`
