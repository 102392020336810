import React from 'react'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'
import { GoChevronRight } from 'react-icons/go'
import { RiShareBoxLine } from 'react-icons/ri'
import Image from '../../image'
import Anchor from '../../ui/anchor'
import {
  BoxLargeImgWrap,
  BoxLargeImgInner,
  BoxLargeImgMedia,
  BoxLargeImgLink,
  BoxLargeImgContent,
  ImageWrapper,
  HeadingWrap,
  CategoryWrap,
  TextWrap,
  FakeButton,
  BoxLargeImgBtnWrap,
} from './box-large-image.style'

const BoxLargeImage = ({
  color,
  imageSrc,
  imageAltText,
  title,
  category,
  desc,
  path,
  btnText,
  smallIcon,
  ...boxStyles
}) => {
  let boxImage
  const hasIcon =
    imageSrc &&
    imageSrc.file &&
    imageSrc.file.contentType &&
    imageSrc.file.contentType.includes('svg')

  const {
    boxStyle,
    contentBoxStyle,
    headingStyle,
    descStyle,
    btnStyle,
  } = boxStyles

  if (imageSrc && imageSrc.fixed && typeof imageSrc.fixed !== 'function') {
    boxImage = <Img fixed={imageSrc.fixed} alt={imageAltText} />
  } else if (imageSrc && imageSrc.fluid) {
    boxImage = <Image fluid={imageSrc.fluid} alt={imageAltText} />
  } else if (imageSrc && imageSrc.file && imageSrc.file.url) {
    boxImage = <img src={imageSrc.file.url} alt={imageAltText} />
  } else if (imageSrc) {
    boxImage = smallIcon ? (
      <img src={imageSrc} alt={imageAltText} />
    ) : (
      <ImageWrapper>
        <img src={imageSrc} alt={imageAltText} />
      </ImageWrapper>
    )
  } else {
    boxImage = ''
  }

  return (
    <BoxLargeImgWrap {...boxStyle}>
      <BoxLargeImgInner>
        {imageSrc && (
          <BoxLargeImgMedia
            className={hasIcon ? 'icon' : 'image'}
            smallIcon={smallIcon}
          >
            {boxImage}
            <BoxLargeImgLink path={path}>{title}</BoxLargeImgLink>
          </BoxLargeImgMedia>
        )}
        <BoxLargeImgContent color={color} {...contentBoxStyle}>
          {category && <CategoryWrap>{category}</CategoryWrap>}

          {title && (
            <Anchor
              path={path}
              title={
                path && path.startsWith('http')
                  ? `Open de ${title} website in een nieuw tabblad`
                  : `Ga naar de '${title}' pagina`
              }
              aria-label={
                path && path.startsWith('http')
                  ? `Open de ${title} website in een nieuw tabblad`
                  : `Ga naar de '${title}' pagina`
              }
            >
              <HeadingWrap as="h4" {...headingStyle}>
                {title}
              </HeadingWrap>
            </Anchor>
          )}

          {desc && <TextWrap {...descStyle}>{desc}</TextWrap>}

          <BoxLargeImgBtnWrap>
            <FakeButton className="button" {...btnStyle}>
              <span>{btnText}</span>
              {path && path.startsWith('http') ? (
                <RiShareBoxLine role="img" title={btnText} />
              ) : (
                <GoChevronRight role="img" title={btnText} />
              )}
            </FakeButton>
          </BoxLargeImgBtnWrap>
        </BoxLargeImgContent>
      </BoxLargeImgInner>
    </BoxLargeImgWrap>
  )
}

BoxLargeImage.propTypes = {
  color: PropTypes.string,
  title: PropTypes.string,
  imageAltText: PropTypes.string,
  imageSrc: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  desc: PropTypes.string,
  path: PropTypes.string,
  btnText: PropTypes.string,
  headingStyle: PropTypes.object,
  descStyle: PropTypes.object,
  btnStyle: PropTypes.object,
}

BoxLargeImage.defaultProps = {
  path: '/',
  btnText: 'Bekijk artikel',
  btnStyle: {
    iconposition: 'right',
    icondistance: '4px',
    varient: 'texted',
    hover: 'false',
    display: 'flex',
    justify: 'center',
    alignitems: 'center',
    height: '100%',
  },
}

export default BoxLargeImage
