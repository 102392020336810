import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import { LogoWrapper } from './logo.style'

const Logo = (props) => {
  return (
    <LogoWrapper {...props}>
      <Link
        to="/"
        title="Logo Regio West Brabant"
        aria-label="Logo Regio West Brabant"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 280 110"
          width="136"
          height="52"
          role="img"
          aria-label="RWB logo"
        >
          <title>Logo Regio West Brabant</title>

          <g className="logo">
            <g className="brandmark" fill="#d90024">
              <path d="M116.38,38.08a10.58,10.58,0,1,1-12.58-17,49.1,49.1,0,1,0,12.58,17M94.57,46.55A4.45,4.45,0,1,1,99,42.11a4.44,4.44,0,0,1-4.44,4.44" />
              <path d="M143.5,15a15,15,0,1,1-15-15,15,15,0,0,1,15,15" />
            </g>

            {props.type !== 'icon' && (
              <g className="wordmark">
                <path d="M0,78.85V41.32H7.2c9,0,13.44,2.59,13.44,10.36,0,3.55-1.13,8-7.58,8.89,3.5.76,5.3,4.07,5.92,6,3.64,11.49,4.4,12.34,4.37,12.25H15.46S14,74.28,12.73,70.53c-1-3-1.61-7-5.51-7.25V78.85ZM13.7,52.59c0-4-2.31-4.91-6.5-4.91h0V57.5h.16C11.63,57.5,13.7,56.18,13.7,52.59Z" />
                <path
                  d="M65.39,78.85V41.32h7.69c7.84,0,12.69,2.15,12.69,9.4,0,4.79-2.25,7.78-7.4,8.74,5.69.54,8.78,3,8.78,8.5,0,7.24-3.05,10.89-11.91,10.89Zm13.29-27c0-3.29-2.12-4.43-5.36-4.43h-.71v8.86h.71C76.62,56.24,78.68,55.05,78.68,51.81Zm1.07,15.67c0-3.41-2.06-4.67-5.65-4.67H72.61v9.64H74.1C77.57,72.45,79.75,71,79.75,67.48Z"
                  fill="#fff"
                />
                <path
                  d="M32.65,78.71,26.19,41.47h8l1.68,14.86c.5,4.25.83,8.51,1.28,13h.16c.5-4.59,1.33-8.63,2.1-13.1l2.07-14.81h7l1.9,14.7c.66,4.19,1.33,8.34,1.77,13.21h.11c.5-4.87.94-8.85,1.44-13.16l1.68-14.75H63L56.31,78.71h-8l-2-12.21c-.61-3.43-1.16-7.68-1.66-12.22h-.11c-.66,4.48-1.28,8.63-2,12.28L40.32,78.71Z"
                  fill="#fff"
                />
              </g>
            )}
          </g>

          {props.type === 'full' && (
            <g className="name">
              <path d="M136.27,57.76c-1.47-2,.06-8.74-4.15-8.74h-2.37v8.74h-2V38.19h4.62c3,0,5.31,1.37,5.31,5.24,0,2.56-1.19,4.47-4,4.78,4.9.22,3,7.09,4.75,9.55Zm-4.12-10.49c1.81,0,3.43-1,3.43-3.84,0-2.65-1.56-3.46-3.34-3.46h-2.49v7.3Z" />
              <path d="M140.76,57.76V38.19h8.9V40h-6.9v6.9h5.83v1.78h-5.83V56h7v1.78Z" />
              <path d="M157.33,37.84c3.5,0,5.59,2.91,5.47,6.65l-2,.13c.06-2.5-1-5-3.44-5s-4,2.38-4,8.31c0,5.68,1.5,8.4,4,8.4,1.5,0,2.78-1.19,3.44-3.06V49.14h-4.5V47.4h6.49V57.76h-1.4L161,54.14c-.4,2.22-1.87,3.93-4.15,3.93-3.12,0-5.55-2.81-5.55-10.11S153.87,37.84,157.33,37.84Z" />
              <path d="M166.08,57.76V38.19h2V57.76Z" />
              <path d="M177,37.84c3.53,0,6.12,2.88,6.12,10.12S180.55,58.1,177,58.1,170.9,55.2,170.9,48,173.49,37.84,177,37.84Zm0,1.81c-2.46,0-4,2.63-4,8.31s1.57,8.33,4,8.33,4-2.65,4-8.33S179.49,39.65,177,39.65Z" />
              <path d="M140.3,79.93l3.15-17.51h2L141.67,82H139L136,64.54,133.09,82h-2.72l-3.81-19.57h2.06l3.16,17.51,3-17.51h2.59Z" />
              <path d="M147.8,82V62.42h8.9V64.2h-6.9v6.9h5.84v1.78H149.8v7.33h7V82Z" />
              <path d="M158.41,75.69l2-.13c-.06,2.56.78,5,3.44,5a2.84,2.84,0,0,0,3-3.15c0-1.72-.81-3-2.8-4.15l-2-1.16a5.9,5.9,0,0,1-3.28-5.33,4.48,4.48,0,0,1,4.84-4.66c3.52,0,5.15,2.5,5,5.78l-2,.12c0-2.59-.93-4.12-3-4.12a2.61,2.61,0,0,0-2.78,2.84,4.1,4.1,0,0,0,2.37,3.69l2,1.15c2.4,1.41,3.77,3.13,3.77,5.69,0,3.27-2,5.11-5.05,5.11C160.1,82.33,158.29,79.53,158.41,75.69Z" />
              <path d="M174.58,82V64.2h-4.37V62.42H181V64.2h-4.37V82Z" />
              <path d="M180.8,72.91h5.62v1.75H180.8Z" />
              <path d="M189.35,82V62.42h4.46c3.25,0,5.37,1.53,5.37,5.09,0,2.34-1.09,4.12-3.43,4.43,2.68.12,3.9,2,3.9,4.62,0,3.9-2,5.43-5.62,5.43Zm7.74-14.42c0-2.47-1.28-3.37-3.25-3.37h-2.5v6.9h2.44C195.81,71.07,197.09,70,197.09,67.57Zm.47,9c0-2.56-1.31-3.75-3.5-3.75h-2.72v7.43h2.78C196.21,80.27,197.56,79.34,197.56,76.59Z" />
              <path d="M210.84,82c-1.47-2,.06-8.74-4.15-8.74h-2.37V82h-2V62.42h4.62c3,0,5.31,1.37,5.31,5.24,0,2.56-1.19,4.47-4,4.78,4.9.22,3,7.09,4.75,9.55ZM206.72,71.5c1.81,0,3.43-1,3.43-3.84,0-2.65-1.56-3.46-3.34-3.46h-2.49v7.3Z" />
              <path d="M223.63,76.43h-5.74L216.54,82h-2l5-19.57h2.56l5,19.57H225Zm-5.28-1.77h4.81l-2.4-10.18Z" />
              <path d="M229.21,82V62.42h4.47c3.24,0,5.37,1.53,5.37,5.09,0,2.34-1.1,4.12-3.44,4.43,2.69.12,3.91,2,3.91,4.62,0,3.9-2,5.43-5.62,5.43ZM237,67.57c0-2.47-1.28-3.37-3.25-3.37h-2.5v6.9h2.44C235.68,71.07,237,70,237,67.57Zm.46,9c0-2.56-1.31-3.75-3.49-3.75h-2.72v7.43H234C236.08,80.27,237.42,79.34,237.42,76.59Z" />
              <path d="M249.78,76.43H244L242.69,82h-2l5-19.57h2.56l5,19.57h-2.09Zm-5.28-1.77h4.81l-2.4-10.18Z" />
              <path d="M263.54,82l-6.37-17,.13,17h-1.94V62.42h2.72l6.37,17.07-.16-17.07h2V82Z" />
              <path d="M272.7,82V64.2h-4.37V62.42h10.74V64.2H274.7V82Z" />
            </g>
          )}
        </svg>
      </Link>
    </LogoWrapper>
  )
}

Logo.propTypes = {
  type: PropTypes.oneOf(['full', 'small', 'icon']),
  justifycontent: PropTypes.string,
}

Logo.defaultProps = {
  type: 'full',
  justifycontent: 'flex-start',
}

export default Logo
