import styled, { css } from 'styled-components'
import { Link } from 'gatsby'

export const Menu = styled.ul``

export const MenuItem = styled.li(({ isNested }) => [
  css`
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  `,
  isNested &&
    css`
      :last-child {
        border: 0;
      }
    `,
])

export const MenuButton = styled.button(
  ({ isNested, theme: { fontFamily } }) => [
    css`
      align-items: center;
      background: transparent;
      border: 0;
      display: flex;
      font-family: ${fontFamily.heading};
      font-size: 16px;
      font-weight: 500;
      line-height: 1.5;
      padding: 10px 0px;
      text-align: left;
      text-transform: uppercase;
      width: 100%;
    `,
    isNested &&
      css`
        font-family: ${fontFamily.text};
        font-size: 15px;
        padding: 6px 0px;
        text-transform: none;
      `,
  ],
)

export const MenuButtonIconWrapper = styled.div(
  ({ isExpanded, isNested, theme: { colors } }) => [
    css`
      background: rgba(0, 0, 0, 0.1);
      border-radius: 100%;
      color: ${colors.textColor};
      font-size: 25px;
      height: 40px;
      line-height: 40px;
      text-align: center;
      transform: rotateZ(${isExpanded ? '180deg' : '0deg'});
      transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
      width: 40px;
    `,
    isNested &&
      css`
        height: 30px;
        line-height: 30px;
        width: 30px;
      `,
  ],
)
export const MenuButtonText = styled.div(
  ({ isActive, theme: { colors } }) => css`
    color: ${isActive ? colors.themeColor : colors.textDark};
    flex: 1 1 auto;
  `,
)

export const MenuLink = styled(Link)(
  ({ theme: { colors } }) => css`
    color: ${colors.textDark};
    display: block;
    font-size: 15px;
    font-weight: 500;
    line-height: 1.5;
    padding: 10px 0px;

    &.active {
      color: ${colors.themeColor};
    }
  `,
)

export const SubMenu = styled.ul(
  ({ isExpanded }) => css`
    box-shadow: rgb(0 0 0 / 5%) 0px 0px 0px;
    border-top: 1px solid rgba(0, 0, 0, 0.15);
    max-height: ${isExpanded ? '1000px' : 0};
    opacity: ${isExpanded ? 1 : 0};
    overflow: hidden;
    padding-left: 14px;
    transition: all 0.5s ease-in-out;
  `,
)
export const SubMenuItem = styled.li`
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  :first-child {
    padding-top: 12px;
  }
  :last-child {
    border-bottom: 0;
    padding-bottom: 10px;
  }
`
