import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { useMedia } from 'react-use-media'
import OffCanvas, {
  OffCanvasHeader,
  OffCanvasBody,
} from '../../components/ui/off-canvas'
import Logo from '../../components/logo'
import Button from '../../components/ui/button'
import BurgerButton from '../../components/ui/burger-button'
import { MenuData, MainMenu, MobileMenu } from '../../components/menu'
import { MdSearch } from 'react-icons/md'
import { FiPhone } from 'react-icons/fi'
import { size } from '../../theme'

import {
  HeaderWrap,
  HeaderBottom,
  FixedHeader,
  HeaderMain,
  HeaderLeft,
  HeaderMiddleLogo,
  HeaderRight,
  HeaderNavigation,
  HeaderElement,
  FixedHeaderHeight,
} from './header.style'

const Header = (props) => {
  const menuArray = MenuData({ menu: 'all' })
  const isMobile = useMedia({ maxWidth: size.small })
  const isTablet = useMedia({ maxWidth: size.large })
  const [offCanvasOpen, setOffcanvasOpen] = useState(false)
  const [fixedHeaderHeight, setFixedHeaderHeight] = useState(0)
  const [fixedMainHeaderHeight, setFixedMainHeaderHeight] = useState(0)
  const [totalHeaderHeight, setTotalHeaderHeight] = useState(0)
  const [sticky, setSticky] = useState(false)
  const [mainSticky, setMainSticky] = useState(false)
  const headerRef = useRef(null)
  const mainFixedRef = useRef(null)
  const fixedRef = useRef(null)
  const offCanvasRef = useRef(null)

  const MainHeaderWrapper = ({ children }) => {
    if (isTablet) {
      return (
        <>
          <FixedHeader ref={mainFixedRef} isSticky={mainSticky}>
            <HeaderMain className="container-xl">{children}</HeaderMain>
          </FixedHeader>
          <FixedHeaderHeight height={fixedMainHeaderHeight} />
        </>
      )
    } else {
      return (
        <HeaderMain ref={mainFixedRef} className="container-xl">
          {children}
        </HeaderMain>
      )
    }
  }

  const offCanvasHandler = () => {
    setOffcanvasOpen((prevState) => !prevState)
  }

  useEffect(() => {
    setFixedHeaderHeight(fixedRef.current.clientHeight)
    setFixedMainHeaderHeight(mainFixedRef.current.clientHeight)
    setTotalHeaderHeight(headerRef.current.clientHeight)
  }, [isTablet])

  useEffect(() => {
    const scrollHandler = () => {
      let scrollPos = window.scrollY

      if (!sticky && !isTablet && scrollPos > totalHeaderHeight) {
        setSticky(true)
      }

      if (sticky && !isTablet && scrollPos < totalHeaderHeight) {
        setSticky(false)
      }

      if (!mainSticky && isTablet && scrollPos > totalHeaderHeight) {
        setMainSticky(true)
      }

      if (mainSticky && isTablet && scrollPos <= 0) {
        setMainSticky(false)
      }
    }

    window.addEventListener('scroll', scrollHandler)
    return () => {
      window.removeEventListener('scroll', scrollHandler)
    }
  }, [
    sticky,
    mainSticky,
    fixedHeaderHeight,
    fixedMainHeaderHeight,
    totalHeaderHeight,
    isTablet,
  ])

  const { headerBottom } = props
  const { logoStyle, burgerBtnElStyle } = headerBottom

  useEffect(() => {
    // This shouldn't be needed, if header gets refactored remove the timeout
    offCanvasOpen &&
      setTimeout(() => offCanvasRef.current.querySelector('a').focus(), 100)
  }, [offCanvasRef, offCanvasOpen])

  return (
    <>
      <OffCanvas
        scrollable={true}
        isOpen={offCanvasOpen}
        onClick={offCanvasHandler}
        ref={offCanvasRef}
      >
        <OffCanvasHeader onClick={offCanvasHandler}>
          <Logo darkLogo align={{ default: 'flex-start' }} />
        </OffCanvasHeader>
        <OffCanvasBody>
          <MobileMenu menuData={menuArray} />
        </OffCanvasBody>
      </OffCanvas>
      <HeaderWrap ref={headerRef}>
        <MainHeaderWrapper>
          <HeaderLeft>
            <HeaderElement {...burgerBtnElStyle}>
              <BurgerButton
                onClick={offCanvasHandler}
                title="Open het menu"
                aria-label="Open het menu"
              >
                <small>Menu</small>
              </BurgerButton>
            </HeaderElement>
          </HeaderLeft>
          <HeaderMiddleLogo>
            <Logo type={isMobile ? 'small' : 'full'} {...logoStyle} />
          </HeaderMiddleLogo>

          <HeaderRight>
            <Button
              className="contact-btn"
              path="/"
              to="/contact"
              skin="transparent"
              iconposition="left"
              icon={<FiPhone role="img" title="Contact" aria-label="Contact" />}
              title="Ga naar de contact pagina"
              aria-label="Ga naar de contact pagina"
            >
              <span>Contact</span>
            </Button>

            {props.search && (
              <Button
                className="search-btn"
                path="/"
                to="/zoeken"
                skin="transparent"
                iconposition="left"
                icon={
                  <MdSearch
                    role="img"
                    title="Open het zoekvenster"
                    aria-label="Open het zoekvenster"
                  />
                }
                title="Ga naar de zoek pagina"
                aria-label="Ga naar de zoek pagina"
              >
                <span>Zoeken</span>
              </Button>
            )}
          </HeaderRight>
        </MainHeaderWrapper>
        <HeaderBottom>
          <FixedHeader ref={fixedRef} isSticky={sticky}>
            <HeaderNavigation id="navigation">
              <MainMenu menuData={menuArray} />
            </HeaderNavigation>
          </FixedHeader>
          <FixedHeaderHeight height={fixedHeaderHeight} />
        </HeaderBottom>
      </HeaderWrap>
    </>
  )
}

Header.propTypes = {
  headerTop: PropTypes.object,
  search: PropTypes.bool,
}

Header.defaultProps = {
  search: true,
  headerTop: {
    textStyle: {
      fontSize: '14px',
      align: 'center',
      lineHeight: 1.78,
      pt: '10px',
      pb: '10px',
    },
    linkStyle: {
      fontWeight: 500,
    },
  },
  headerBottom: {
    logoStyle: {
      align: {
        default: 'center',
        lg: 'flex-start',
      },
    },
    langElStyle: {
      pr: '25px',
      responsive: {
        xlarge: {
          pr: '10px',
        },
      },
    },
    headerFormElStyle: {
      bl: '1px solid #eee',
      responsive: {
        xsmall: {
          mt: '15px',
        },
      },
    },
    searchElStyle: {
      pl: '50px',
    },
    clickAbleElStyle: {
      pl: '15px',
    },
    clickAble: {
      fontsize: '20px',
      color: '#6D70A6',
    },
  },
}

export default Header
